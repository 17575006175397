import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const NestedList = tw.div`ml-10 mt-4`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-decimal list-inside`}
    li {
      ${tw`mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "[TEC SERVICE] TERMS AND CONDITIONS OF USE" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: August 5, 2020</p>

            <p>Please read these terms and conditions of use as set out below (the <strong>“Terms”</strong>) carefully before using the <strong>[TEC Service]</strong> web site and mobile application (the <strong>“Service”</strong>). These Terms govern your access to and use of this Service, including the messages, information, data, text, software, images and other content that make up this site (the <strong>“Content”</strong>, which content is part of this Service). These Terms exempt Rubricem Inc. (<strong>“Rubricem”</strong>, <strong>“we”</strong> or <strong>“us”</strong>) and others from liability and/or limit our and their liability and contain other important provisions that apply to your use of this site.</p>

            <br/>

            <p><strong>1. Terms of Use of Service</strong></p>

            <p>Your use of this Service is conditional on your acceptance of these Terms. By visiting or using this Service you agree on your own behalf, and on behalf of any organization on whose behalf you may act (collectively referred to herein as “you”), to accept and abide by these Terms for each use of and each visit to this Service. You also acknowledge that you have read and understand our privacy statement available at <a href="https://rubricem.com/privacy">https://rubricem.com/privacy</a> (the <strong>“Privacy Statement”</strong>).</p>

            <p>If you do not agree to abide by these Terms, you cannot use this Service or register to become a member of this Service. You agree to have these Terms and any related information made available to you, and to otherwise have communications between you and us occur, electronically.</p>

            <p>We have the right, in our sole discretion, to add to, remove, modify or otherwise change any part of these Terms, in whole or in part, at any time. If we exercise this right, the “Last Update” notice at the top of this document shall be amended to reflect the last date of such changes. Changes will be effective as of the date the changes to these Terms are posted to this Service. It is your responsibility to check these Terms each time you access this Service to determine whether any changes have been made, including by checking the date of the “Last Update” at the top of these Terms. If any change to these Terms is not acceptable to you, you must discontinue your use of this Service immediately. Your continued use of this Service after any such changes are posted will constitute acceptance of those changes. These Terms apply exclusively to your use of this Service and do not alter the terms or conditions of any other agreement you may have with us.</p>

            <p>We may, at any time without notice or liability, and for any reason whatsoever, terminate, change, suspend or discontinue any aspect of this Service, including: (a) changing the availability of, restricting access to, or imposing limits on any or all features or services on, or links to, this Service; (b) removing, adding, modifying or otherwise changing any fees or charges arising out of use of this Service or any features of this Service; and (c) removing, adding, modifying or otherwise changing any Content on this Service. We reserve the right, in our sole discretion, to correct any errors or omissions in any portion of this Service at any time without notice, but confirm that we have no duty to do so.</p>

            <p>To use the Service, you must be 18 years of age or older and of at least the age of majority in the jurisdiction in which you reside as of the time you use the Service.</p>

            <p><strong>2. Limited Licenses</strong></p>

            <p>Subject to these Terms, you are granted a non-exclusive, non-transferable, non-sub-licensable, revocable, limited right and license to use this Service, including the Content therein accessible through this Service, solely for informational purposes and solely for your own personal use.</p>

            <p>You may not, nor may you cause or assist another to:</p>

            <p>(a) modify, reverse engineer, decompile, create derivative works from, or disassemble this Service for any reason whatsoever, including for the purpose of creating competitive products or services;</p>

            <p>(b) resell any Content or include any Content in or with any product that you create or distribute;</p>

            <p>(c) copy any Content onto your own or any other web site or into a database or mobile application;</p>

            <p>(d) use this Service in any manner that could damage, disable, overburden, impair, interfere with the security of, negatively affect the functioning of, or otherwise abuse, this Service or any other services, system resources, accounts, servers, networks, affiliated or linked sites connected to or accessible through this Service (including without limitation uploading, posting or otherwise transmitting on this Service any computer viruses, trojan horses, worms or other files or computer programs which are potentially harmful, disruptive or destructive or that may impose an unreasonable or disproportionately large load on the infrastructure of this Service);</p>

            <p>(e) use any robot, spider or other automatic program or device, or manual process to monitor, copy, summarize, or otherwise extract information from this Service or the Content in whole or in part;</p>

            <p>(f) use this Service to attempt to identify a particular individual, including by using filters, asking targeted questions, by applying any sort of mosaic theory, or by utilizing external utilities;</p>

            <p>(g) use this Service in any manner that may dilute or depreciate our name or reputation, our Marks (as defined below) or our affiliates or associates; or</p>

            <p>(h) interfere with any other persons’ use and enjoyment of this Service or of the Internet generally.</p>

            <p>To the extent you are in breach of your obligations under these Terms, Rubricem may investigate occurrences which may involve violations of such laws and co-operate with law enforcement authorities in prosecuting users who are involved in such violations. We reserve the right at all times to disclose any information (including without limitation your personal information or your identity) regarding your usage of this Service (including any perceived violations of applicable law), in each case as may be permitted by or required to satisfy applicable law.</p>

            <p><strong>3. Membership and Accounts</strong></p>

            <p>To use the Service (to be a <strong>“User ”</strong>), you must register for an account to become a member of the Service:</p>

            <p>(a) You must register as a member by providing a valid email address. You must provide complete and accurate information to us and update your information as it changes. Submission of such registration information does not guarantee that you will be granted membership to use the Service. Any personal information which Rubricem collects via the Service is collected in accordance with our Privacy Statement. You hereby acknowledge that the collection, use and disclosure of your personal information pursuant to the information provided in the Privacy Statement is for reasonable and appropriate purposes, and is with your knowledge and consent.</p>

            <p>(b) You should not share your User name or password with any other person. You acknowledge and agree that you are responsible for all activity occurring under your User account, and shall abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with use of the Service, including those related to data privacy, international communications and the transmission of technical or personal information. You shall: (A) notify us immediately of any unauthorized use of any password or User account or any other known or suspected breach of security; and (B) not impersonate another person or provide false identity information to gain access to or use the Service. Without limitation, we may immediately remove or suspend any User account that falls, or is suspected of falling, under the scope of the foregoing paragraphs (A) or (B).</p>

            <p>(c) If you become aware of an unauthorized access to your account, you must change your password and, if you feel the unauthorized access is detrimental to your usage of the Service, notify us immediately. You agree to notify us promptly of any unauthorized use of your password and you will remain liable for any use of this Service to the extent permitted by law.</p>

            <p>(d) You must not use this Service in any manner that is unlawful (including by accessing this Service from any location where such access may be illegal or prohibited), unethical, indecent, offensive, defamatory, derogatory, fraudulent, deceptive, harmful, abusive, threatening, vulgar, profane, pornographic, obscene, sexually explicit, sexist, racist, hateful, offensive, harassing, invasive of the privacy rights of others, including the posting of any materials that depict, encourage, indicate, advocate or tend to incite any such conduct, or is otherwise objectionable or which does not respect the legal rights and interests of others.</p>

            <p>In these Terms, <strong>“Postings”</strong> refer to the text, images, comments, or other information posted by a User through this Service.</p>

            <p><strong>4. Proprietary Rights</strong></p>

            <p>This Service is protected by Canadian copyright laws and treaty provisions. Any unauthorized copying, redistribution, reproduction or modification of this Service (including any element of the Content) by any person may be a violation of trade-mark and/or copyright laws and could subject such person to legal action. You agree to comply with all copyright laws worldwide in your use of this Service and to prevent any unauthorized copying, redistribution, reproduction or modification of this Service or any of the Content.</p>

            <p>Certain names, graphics, logos, icons, designs, words, titles and phrases on this Service, including without limitation <strong>“[TEC Service trademark]”</strong>, constitute trade-marks, trade names, trade dress and associated products and services of Rubricem or its affiliates (the <strong>“Marks”</strong>) or constitute trade-marks, trade names, trade dress and associated products and services of Rubricem suppliers or other third parties (the <strong>“Third Party Marks”</strong>) and are protected in Canada and internationally and their display on this Service does not convey or create any licence or other rights in the Marks or the Third Party Marks. Any use of the Marks or the Third Party Marks, in whole or in part, without prior written authorization of Rubricem or such third party, as applicable, is strictly prohibited.</p>

            <p><strong>5. Linked Services</strong></p>

            <p>Certain links on this Service may take you to other web sites that are not owned or operated by Rubricem. Rubricem provides these links only as a convenience. Rubricem is not responsible for the content of any such linked web sites. Rubricem makes no representation or warranty regarding, and does not endorse, such linked web sites, the information or other content appearing thereon or any of the products or services available on or through such web sites. If you decide to visit any linked site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses and other destructive elements. We welcome you to link to this Service. However, you agree that if you link to this Service, your website shall not (a) alter the visual presentation of this Service; (b) imply that Rubricem is endorsing you or any other person, or your or such other person’s products or services; (c) imply an affiliation between you or any other person, or your or such other person’s products or services, and Rubricem without the prior written consent of Rubricem; (d) misrepresent the relationship of you or any other person with Rubricem or present false, misleading or otherwise damaging information or impressions about Rubricem or any of its products or services; or (e) contain materials that may be illegal or interpreted as distasteful, harmful, offensive, or inaccurate.</p>

            <p>You acknowledge and agree that in no event will Rubricem provide any form of remuneration for any links you may make to this Service.</p>

            <p><strong>6. Privacy Statement</strong></p>

            <p>Any personal information which Rubricem collects via this Service is collected in accordance with the information provided in Rubricem’s Privacy Statement available at <a href="https://rubricem.com/privacy">https://rubricem.com/privacy</a>. When you interact with this Service, you consent that information about you and your use of this Service, including but not limited to, the type of device, your mobile carrier, your internet access provider, your physical location, and/or web pages containing plugins that load in your browser may be communicated to us.</p>

            <p><strong>7. User Communications</strong></p>

            <p>Any non-identifiable information gathered by us through your use of this Service may be used by us for our own marketing, promotional and product development purposes and more specifically may be stored in a database and used by us to identify, customize and personalize user access, and assess utilization of this Service. Such information may be shared with our affiliates, suppliers, licensors, partners and clients in furtherance of the forgoing purposes.</p>

            <p><strong>8. User Submissions (Solicited and Unsolicited)</strong></p>

            <p>(a) Subject to any applicable law:</p>

            <NestedList>(i) We do not wish to obtain unsolicited ideas, including ideas for new advertising campaigns, new promotions, new or improved products, goods, services or technologies, product enhancements, processes, materials, marketing plans, or new product names. Accordingly, please do not send any unsolicited ideas, suggestions or other materials (<strong>“Submissions”</strong>) to us.</NestedList>

            <NestedList>(ii) Any communications sent by you to us via this Service or otherwise, including Submissions, whether solicited by us or otherwise, are on a non-confidential basis (other than personal information which is covered under our Privacy Statement), and we are free to use and disclose the content of any such communication, including without limitation any ideas, inventions, concepts, techniques or know-how disclosed therein, for any purpose including without limitation developing, manufacturing and/or marketing goods and services. You agree to not assert any ownership right of any kind in such communications (including without limitation copyright, trade-mark, patent, trade secret, unfair competition, moral rights, or implied contract) and you hereby waive such moral rights in favour of Rubricem and its affiliates, licensees, successors and assignees. You also acknowledge that you have no right to receive any financial or other consideration in connection with such communication.</NestedList>

            <p>(b) We are not obliged to monitor, screen, police or edit your use of this Service, including any Postings or other content you or others may contribute to this Service, although we may choose to do so in our sole discretion. We will respond as we consider appropriate, in our sole discretion, if we become aware of any inappropriate uses of this Service, including without limitation use that constitute copyright infringement.</p>

            <p>(c) Some Postings and Content on the Service may be legal or medical in nature. Such Postings and Content should be not be relied on as a substitute for advice from a professional that is appropriate for your specific situation.</p>

            <p><strong>9. Disclaimer</strong></p>

            <p>We make no representation or warranty and there are no conditions of any kind regarding this Service, any Users, the Content or the Postings, including without limitation any representation, warranty or condition regarding the accuracy, reliability, currency, or completeness of the Content or the Postings, including that they are appropriate or available for use at any locations outside Canada. Rubricem does not, and is not responsible to, validate or verify any Postings or other information provided by you or other Users of the Service. Accessing the Service, the Content or the Postings from locations where the Service, the Content or the Postings is illegal is prohibited. Any diversion of the services and/or any Content or Postings obtained from or through the Service contrary to Canadian law is also prohibited. If you access this Service from locations other than Canada, then you do so on your own initiative and you are solely responsible for compliance with all applicable laws.</p>

            <p>You acknowledge and agree that this Service (including without limitation the Users, the Content and the Postings) is provided on an “AS IS” an “AS AVAILABLE” basis and that any use of or reliance on this Service shall be at your sole risk. We do not screen or censor the Postings.</p>

            <p>RUBRICEM AND ITS AFFILIATES DO NOT MAKE ANY, AND EXPRESSLY DISCLAIM ALL, REPRESENTATIONS, WARRANTIES, COVENANTS AND CONDITIONS, EXPRESS OR IMPLIED, BY OPERATION OF LAW OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABLE QUALITY, TITLE, ACCURACY, COMPLETENESS OR FITNESS FOR ANY PARTICULAR PURPOSE, OR NON-INFRINGEMENT, OR THOSE ARISING OUT OF COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE WITH RESPECT TO THIS SERVICE (INCLUDING THE USERS, CONTENT OR POSTINGS). FOR GREATER CERTAINTY, RUBRICEM DOES NOT WARRANT THAT THIS SERVICE WILL (A) MEET YOUR REQUIREMENTS, (B) BE COMPATIBLE WITH YOUR COMPUTER OR MOBILE DEVICE OR ANY RELATED EQUIPMENT, (C) BE RELIABLE, ACCURATE, AUTHENTIC, CURRENT OR COMPLETE, (D) CONTINUE TO OPERATE, (E) OPERATE WITHOUT INTERRUPTIONS, OR (F) BE ERROR-FREE.</p>

            <p><strong>10. Limitation of Liability</strong></p>

            <p>YOU AGREE THAT IN NO EVENT WILL RUBRICEM AND ANY OF ITS AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES (COLLECTIVELY “RUBRICEM REPRESENTATIVES”) HAVE ANY RESPONSIBILITY OR LIABILITY IN CONNECTION WITH THIS SERVICE, THE USERS, THE CONTENT AND THE POSTINGS FOR ANY LOSSES OR DAMAGES WHATSOEVER, WHETHER BASED ON CONTRACT (INCLUDING FUNDAMENTAL BREACH OR BREACH OF A FUNDAMENTAL TERM), TORT (INCLUDING NEGLIGENCE) OR OTHER LEGAL OR EQUITABLE BASIS, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR OTHER DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES FOR HARM TO BUSINESS, LOSS OF INFORMATION OR DATA, LOSS OF PROFIT, LOSS OF SAVINGS OR REVENUE, LOSS OF GOODWILL OR OTHER ECONOMIC LOSS), ARISING FROM OR IN CONNECTION WITH OR RELATING TO THE USE OF OR ACCESS TO, OR ANY INCONVENIENCE, DELAY OR LOSS OF USE OF OR ACCESS TO, THIS SERVICE, THE USERS, THE CONTENT OR THE POSTINGS OR ANY CONTENT OF ANY LINKED WEB SITE, OR FAILURE OF SUCH WEB SITES (INCLUDING WITHOUT LIMITATION ANY DAMAGES SUFFERED AS A RESULT OF OMISSIONS OR INACCURACIES IN SUCH WEB SITES, USERS, CONTENT OR POSTINGS, OR THE TRANSMISSION OF CONFIDENTIAL OR SENSITIVE INFORMATION TO OR FROM SUCH WEB SITES), EVEN IF WE OR ANY RUBRICEM REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS OR IF SUCH DAMAGE OR LOSS WAS FORESEEABLE.</p>

            <p>YOU ACKNOWLEDGE THAT RUBRICEM ACTS AS TRUSTEE FOR THE RUBRICEM REPRESENTATIVES WITH RESPECT TO ALL RIGHTS CONTEMPLATED HEREUNDER ARISING IN FAVOUR OF A RUBRICEM REPRESENTATIVE. RUBRICEM AGREES TO ACCEPT SUCH TRUST AND HOLD AND ENFORCE SUCH RIGHTS ON BEHALF OF EACH RUBRICEM REPRESENTATIVE.</p>

            <p><strong>11. Indemnity</strong></p>

            <p>You will indemnify and hold the Rubricem Representatives harmless from and against any claims brought by third parties arising out of your use of this site, and any breach of these Terms by you, including any use of the Content other than as expressly authorized in these Terms. You agree that the Rubricem Representatives will have no liability in connection with any such breach or unauthorized use or modification, and agree to indemnify against any and all resulting loss, damages, judgments, awards, costs, expenses, and lawyers’ fees of the Rubricem Representatives in connection therewith.</p>

            <p><strong>12. Choice of law</strong></p>

            <p>You agree that all matters relating to the access to, or use of, this Service shall be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein, without reference to its conflict of laws principles, and that you will comply with all such applicable laws.</p>

            <p>Any dispute between us and you or any other person arising from, in connection with or relating to this Service, these Terms, any transaction through this Service or any related matters must be resolved before the Courts of the Province of Ontario, Canada sitting in the City of Toronto, and you hereby irrevocably submit and attorn to the exclusive jurisdiction of those Courts in respect of any such dispute.</p>

            <p><strong>13. Termination of Use</strong></p>

            <p>If you breach any provision of these Terms, then you may no longer use this Service. We, in our sole discretion, shall determine whether these Terms have been violated. You agree that we may, in our sole discretion, terminate or suspend your account with or without notice.</p>

            <p>Upon termination or suspension of your account, regardless of the reasons therefore, your right to use this Service immediately ceases, and you acknowledge and agree that we may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or this Service. We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection with such termination or suspension.</p>

            <p><strong>14. General</strong></p>

            <p>These Terms constitute the entire agreement between us and you with respect to the subject matter hereof, and supersede all communications, representations or agreements, either oral or written, between us or our affiliates and you with respect to this subject matter. If for any reason a court of competent jurisdiction finds any provision of these Terms or portion thereof to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of these Terms, and the remainder of these Terms shall continue in full force and effect.</p>

            <p>No waiver of or consent to depart from the requirements of any provision of these Terms will be binding unless it is in writing and signed by Rubricem. You and Rubricem are independent contractors, and no agency, partnership, joint venture, employment or franchise relationship is intended or created by these Terms.</p>

            <p>The provisions of these Terms will enure to the benefit of and be binding upon you and Rubricem and its respective successors and assigns, and, if you are contracting as an individual, your heirs, executors, administrators and personal representatives. You may not assign these Terms or your rights and obligations under these Terms without our express prior written consent, which may be withheld in our sole discretion. We may assign these Terms and our respective rights and obligations under these Terms without your consent.</p>

            <p><strong>15. Notices</strong></p>

            <p>All notices to us shall be in writing and shall be made via email. Notices to us must be sent to the attention of our customer service representatives at [info@Rubricem.com]. Notices to you may be sent, in our sole discretion, to the address or email address supplied by you as part of your membership. In addition, we may broadcast notices or messages through this Service to inform you of changes to this Service or other matters of importance, and such broadcasts shall constitute notice to you at the time of sending.</p>

            <p><strong>16. Language</strong></p>

            <p>The parties have required that this agreement and all documents relating thereto be drawn up in English. Les parties ont demandé que cette convention ainsi que tous les documents qui s’y attachent soient rédigés en langue anglaise.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
