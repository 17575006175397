import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import ServiceLandingPage from "demos/ServiceLandingPage.js";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ContactUs from "pages/ContactUs";

export default function App() {

  return (
    <Router>
      <Switch>
        <Route path="/privacy">
          <PrivacyPolicy/>
        </Route>
        <Route path="/terms">
          <TermsOfService/>
        </Route>
        <Route path="/contact">
          <ContactUs/>
        </Route>
        <Route path="/">
          <ServiceLandingPage/>
        </Route>
      </Switch>
    </Router>
  );
}