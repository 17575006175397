import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Testimonial/>
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
